import { Injectable } from '@angular/core'
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	mobile: boolean = false

	constructor(private _snackBar: MatSnackBar) {}

	openSnackBar(message: string, duration: number = 5000) {
        this._snackBar.open(message, "Bezár", {
            duration: duration,
            horizontalPosition: !this.mobile ? "right" : "center",
            verticalPosition: !this.mobile ? "top" : "bottom"
        })
    }
}
