import { general } from "./general";

const URL = (function() {
	if (general.production) {
		return 'https://backend.elrek.hu/';
	} else if (general.staging){
		return 'https://staging.backend.elrek.hu/';
	} else if (general.dev) {
		return 'https://dev.backend.elrek.hu/';
	} else {
		return 'http://localhost:3000/';
	}
})()

export const endpoints = {
	// AUTH
	login: URL + 'login',
	register: URL + 'register',
	logout: URL + 'logout',
	checkPermissions: URL + 'check-permissions',
	// checkAuth: URL + 'check-auth',
	checkAdmin: URL + 'check-admin',

	// USERS
	getAllUsers: URL + 'users',
	getUser: URL + 'users/',
	createUser: URL + 'users',
	updateUser: URL + 'users/',
	deleteUser: URL + 'users/',

	// SERVICES
	getServices: URL + 'services',
	getService: URL + 'services/',
	createService: URL + 'services',
	updateService: URL + 'services/',
	deleteService: URL + 'services/',

	// PASSES
	getPasses: URL + 'passes',
	createPass: URL + 'passes',
	updatePass: URL + 'passes/',
	deletePass: URL + 'passes/',

	// ACTIVE PASSES
	getActivePasses: URL + 'active-passes',
	createActivePass: URL + 'active-passes',
	updateActivePass: URL + 'active-passes/',
	deleteActivePass: URL + 'active-passes/',

	// INCOMES
	getIncomes: URL + 'incomes',
	createIncome: URL + 'incomes/multiple-users',
	updateIncome: URL + 'incomes/',
	deleteIncome: URL + 'incomes/',

	// STATISTICS
	getStatistics: URL + 'statistics',
}
